import styled from 'styled-components'
import { fontFamilies } from 'common/styles/mixins'
import { spaces } from '@wongnai/ui/styles/mixins'
import { primary } from '@wongnai/ui/styles/theme'
import ContentContainer from 'common/components/containers/ContentContainer'

export const PageContainer = styled(ContentContainer)`
  padding: 0 ${spaces.large1};
`

export const PhoneLink = styled.a`
  margin-top: auto;
  padding: ${spaces.large2} 0;
  color: ${primary[500]};
  font-family: ${fontFamilies.highlight};
`
