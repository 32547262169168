import React, { useState, useEffect } from 'react'

interface Props {
  className?: string
  time: number
  isActive: boolean
  setIsActive: (state: boolean) => void
}

const CountdownTimer = ({ className, time, isActive, setIsActive }: Props) => {
  const [seconds, setSeconds] = useState(time)

  useEffect(() => {
    let interval: any = null
    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds - 1)
      }, 1000)
    } else if (seconds <= 0) {
      setIsActive(false)
      setSeconds(time)
    }
    return () => clearInterval(interval)
  }, [isActive, seconds, setIsActive])

  if (!isActive) return null
  return <span className={className}>{`(${seconds}s)`}</span>
}

export default CountdownTimer
