import React from 'react'
import Logo from 'common/img/owner-connect-logo.svg'
import { HeaderContainer, Header } from './styled'

const LoginHeader = () => (
  <HeaderContainer>
    <Header>สมัครใช้งาน</Header>
    <img src={Logo} alt="wongnai owner connect" width="245" height="55" />
  </HeaderContainer>
)

export default LoginHeader
