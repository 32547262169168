import { action, observable } from 'mobx'
import ConnectionStore from 'modules/root/stores/ConnectionStore'
import BusinessStore from 'modules/business/stores/BusinessStore'
import LoginStore from 'modules/user/stores/LoginStore'
import ModalStore from 'common/stores/ModalStore'
import AccountsStore from 'modules/user/stores/AccountsStore'

export interface Stores {
  connectionStore: ConnectionStore
  businessStore: BusinessStore
  loginStore: LoginStore
  modalStore: ModalStore
  accountsStore: AccountsStore
}

class RootStore {
  @observable
  stores!: Stores

  constructor() {
    this.initStores()
  }

  @action
  initStores() {
    this.stores = {
      connectionStore: new ConnectionStore(this),
      businessStore: new BusinessStore(this),
      loginStore: new LoginStore(this),
      modalStore: new ModalStore(this),
      accountsStore: new AccountsStore(this),
    }
  }
}

export default RootStore
