import React, { useEffect, useRef } from 'react'
import BaseModal from 'common/components/modals/BaseModal'
import CrossIcon from 'common/img/cross-icon.svg'
import { BusinessSetting } from 'modules/business/types/business'
import useInject from 'common/hooks/useInject'
import {
  SendingTimeSettings,
  NewSettings,
} from 'modules/business/types/settings'
import { TIME_DATA } from 'modules/report/components/SettingsOption/data'
import {
  TitleContainer,
  DescriptionContainer,
  CrossContainer,
  RemarkContainer,
  SendingTimeItem,
  ScrollContainer,
} from './styled'

interface Props {
  setting: BusinessSetting
  toggleModal: () => void
}

const DailyReportSendingTimeModal = ({ setting, toggleModal }: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null)

  const newSetting: SendingTimeSettings = {
    id: setting.id,
    dailyReportSendingTime: setting.dailyReportSendingTime,
  }

  const { updateLocalBusinessSettings } = useInject(({ businessStore }) => ({
    updateLocalBusinessSettings: (newSetting: NewSettings) =>
      businessStore.updateLocalBusinessSettings(newSetting),
  }))

  useEffect(() => {
    const top =
      TIME_DATA.findIndex(
        time => time.value === setting.dailyReportSendingTime,
      ) * 40
    scrollRef?.current?.scrollTo({
      top,
    })
  }, [setting.dailyReportSendingTime, scrollRef?.current?.scrollTop])

  return (
    <BaseModal isOpen>
      <CrossContainer onClick={toggleModal}>
        <img src={CrossIcon} alt="close button" />
      </CrossContainer>
      <TitleContainer>เลือกเวลาตัดรอบรายงานประจำวัน</TitleContainer>
      <DescriptionContainer>{setting.restaurantName}</DescriptionContainer>
      <RemarkContainer>
        *ระบบจะส่งสรุปยอดขายให้ทุกวันตามเวลาตัดรอบที่เลือก
      </RemarkContainer>
      <ScrollContainer ref={scrollRef}>
        {TIME_DATA.map(time => (
          <SendingTimeItem
            onClick={() => {
              newSetting.dailyReportSendingTime = time.value
              updateLocalBusinessSettings(newSetting)
              toggleModal()
            }}
            key={time.value}
          >
            {time.title}
          </SendingTimeItem>
        ))}
      </ScrollContainer>
    </BaseModal>
  )
}

export default DailyReportSendingTimeModal
