import React from 'react'
import Button from '@wongnai/ui/components/Button'
import BaseModal, { BaseModalProps } from 'common/components/modals/BaseModal'
import { TitleContainer, DescriptionContainer, ActionContainer } from './styled'

interface Props extends BaseModalProps {
  title: string
  description: string
  isOpen: boolean
  closeText: string
  actionText: string
  onClickAction: () => void
  onClickClose: () => void
}

const ActionModal = ({
  className,
  title,
  description,
  isOpen,
  actionText,
  closeText,
  onClickAction,
  onClickClose,
  onImpression,
}: Props) => (
  <BaseModal className={className} isOpen={isOpen} onImpression={onImpression}>
    <TitleContainer>{title}</TitleContainer>
    <DescriptionContainer>{description}</DescriptionContainer>
    <ActionContainer>
      <Button variant="flat" onClick={onClickClose} responsive>
        {closeText}
      </Button>
      <Button variant="flatColor" onClick={onClickAction} responsive>
        {actionText}
      </Button>
    </ActionContainer>
  </BaseModal>
)

export default ActionModal
