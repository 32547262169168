import styled from 'styled-components'
import { fontFamilies, fontSizes } from 'common/styles/mixins'
import { spaces } from '@wongnai/ui/styles/mixins'
import { gray } from '@wongnai/ui/styles/colors'
import Button from '@wongnai/ui/components/Button'

export const Icon = styled.img`
  margin-bottom: ${spaces.large2};
  height: auto;
`

export const Title = styled.div`
  font-weight: bold;
  font-size: ${fontSizes.large2};
  font-family: ${fontFamilies.highlight};
  margin-bottom: ${spaces.normal};
`

export const Detail = styled.div`
  color: ${gray[700]};
  font-size: ${fontSizes.small1};
  font-family: ${fontFamilies.highlight};
  margin-bottom: ${spaces.large3};
`

export const CloseAppButton = styled(Button)`
  width: 180px;
  margin-bottom: ${spaces.large3};
`
