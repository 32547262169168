import styled from 'styled-components'
import { spaces } from '@wongnai/ui/styles/mixins'
import { getVh } from '@wongnai/ui/styles/utils/viewport'

const TOTAL_HEIGHT = getVh(100)
const BUTTON_HEIGHT = '72px'

export const ButtonContainer = styled.div`
  width: 100%;
  padding: ${spaces.large2};
`

export const ScrollContainer = styled.div`
  width: 100%;
  height: calc(${TOTAL_HEIGHT} - ${BUTTON_HEIGHT});
  overflow-y: scroll;
`
