import useInject from 'common/hooks/useInject'

function useLoginAction() {
  return useInject(({ loginStore }) => ({
    loginWithEmail: (email: string, password: string) =>
      loginStore.loginWithEmail(email, password),
    requestLoginOtp: (phoneNumber: string) =>
      loginStore.requestLoginOtp(phoneNumber),
    setPhoneNumber: (phoneNumber: string) =>
      loginStore.setPhoneNumber(phoneNumber),
  }))
}

export default useLoginAction
