import React, { ReactNode, Component } from 'react'
import { catchError } from 'core/sentry'
import logIfDev from 'common/utils/logIfDev'
import ErrorComponent from 'core/error/components/ErrorComponent'

type Props = {
  children: ReactNode
}

type State = {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  state = {
    hasError: false,
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    logIfDev(error)
    catchError(error)
  }

  render() {
    const { hasError } = this.state
    if (hasError) {
      return <ErrorComponent />
    }
    const { children } = this.props
    return children
  }
}

export default ErrorBoundary
