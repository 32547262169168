import { Stores } from 'common/stores/RootStore'
import useInject from 'common/hooks/useInject'

function useLoginAction() {
  return useInject(({ loginStore }: Stores) => ({
    loginPos: (email: string, password: string) =>
      loginStore.loginPos(email, password),
  }))
}

export default useLoginAction
