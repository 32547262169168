import { observable, runInAction, action } from 'mobx'
import liff from '@line/liff'

import history from 'core/router/history'
import FetchStateStore from 'common/stores/FetchStateStore'
import { getConnectionStatus } from 'modules/root/api'
import { isSuccess } from 'common/utils/statusUtils'

class ConnectionStore extends FetchStateStore {
  rootStore: RootStore

  @observable
  friendFlag = false

  @observable
  isInitialized = false

  @observable
  connectionStatus = false

  constructor(rootStore: RootStore) {
    super()
    this.rootStore = rootStore
  }

  @action
  async initConnection() {
    await Promise.all([this.getFriendShip(), this.getConnectionStatus()])
    runInAction(() => (this.isInitialized = true))
  }

  @action
  async getFriendShip() {
    try {
      const { friendFlag } = await liff.getFriendship()
      runInAction(() => (this.friendFlag = friendFlag))
    } catch (e) {
      runInAction(() => (this.friendFlag = false))
    }
    return this.friendFlag
  }

  @action
  async getConnectionStatus() {
    try {
      const { status } = await this.fetch(getConnectionStatus())
      runInAction(() => (this.connectionStatus = isSuccess(status)))
    } catch (e) {
      runInAction(() => (this.connectionStatus = false))
    }
  }

  @action
  setConnectionStatus(status: boolean) {
    this.connectionStatus = status
  }

  goToAuthenticate(path?: string) {
    switch (path) {
      case 'pos':
        return history.replace('/pos')
      default:
        return history.replace('/login')
    }
  }

  goToVerifyFriendShip() {
    history.replace('/verify')
  }

  goToSettings() {
    history.replace('/settings')
  }
}

export default ConnectionStore
