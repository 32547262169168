import APIClient from 'core/api/client'
import config from 'core/config'

const apiState = config.api

export function getEndpoint() {
  return apiState.endpoint
}

export function getClient() {
  const client = new APIClient({
    baseURL: getEndpoint(),
  })
  return client
}

const client = getClient()

export { client }
