import styled from 'styled-components'
import { fontFamilies, borders } from 'common/styles/mixins'
import { white } from '@wongnai/ui/styles/colors'

export const ModalContainer = styled.div`
  max-width: 300px;
  width: 100%;
  z-index: 2;
  position: fixed;
  margin: auto;
  top: 50%;
  left: 0;
  right: 0;
  background-color: ${white};
  font-family: ${fontFamilies.highlight};
  border-radius: ${borders.default};
  transform: translateY(-50%);
`
