import styled from 'styled-components'
import Gap from '@wongnai/ui/components/Gap'
import { spaces } from '@wongnai/ui/styles/mixins'
import { gray } from '@wongnai/ui/styles/colors'

export const Footer = styled(Gap)`
  padding: ${spaces.large2} 0;
  border-top: 1px solid ${gray[300]};
  width: 100%;
  justify-content: center;
`
