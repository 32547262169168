export const fontFamilies = {
  default: '"Helvetica Neue", Helvetica, Thonburi, Arial, sans-serif;',
  highlight: '"DB Heavent Now", sans-serif',
  content: '"Helvetica Neue", Helvetica, Thonburi, Arial, sans-serif;',
}

export const fontSizes = {
  large9: '64px',
  large8: '48px',
  large7: '40px',
  large6: '36px',
  large5: '32px',
  large4: '28px',
  large3: '24px',
  large2: '20px',
  large1: '18px',
  normal: '16px',
  small1: '14px',
  small2: '12px',
  small3: '10px',
  small4: '8px',
}
