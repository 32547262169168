import WongnaiTracker from 'core/tracker/WongnaiTracker'

export function trackCloseButton() {
  WongnaiTracker.trackEvent({
    category: 'gettingStartedButton',
    action: 'click',
    name: 'woc',
    screenName: 'wocRegisterComplete',
  })
}

export function trackSettingButton() {
  WongnaiTracker.trackEvent({
    category: 'settingButton',
    action: 'click',
    name: 'woc',
    screenName: 'wocRegisterComplete',
  })
}
