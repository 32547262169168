import React, { useCallback } from 'react'
import BaseModal from 'common/components/modals/BaseModal'
import Button from '@wongnai/ui/components/Button'
import { BusinessSetting } from 'modules/business/types/business'
import useInject from 'common/hooks/useInject'
import {
  NotificationSettings,
  NewSettings,
} from 'modules/business/types/settings'
import NotificationSwitch from 'modules/report/components/NotificationSwitch'
import { NotificationType } from 'modules/report/constants'
import {
  TitleContainer,
  DescriptionContainer,
  ButtonContainer,
  SettingContainer,
  Setting,
} from './styled'

interface Props {
  setting: BusinessSetting
  toggleModal: () => void
}

const NotificationSettingsModal = ({ setting, toggleModal }: Props) => {
  const newSetting: NotificationSettings = {
    id: setting.id,
    enableReceivingDailyReport: setting.enableReceivingDailyReport,
    monthlyReportEnabled: setting.monthlyReportEnabled,
    dailyReviewEnabled: setting.dailyReviewEnabled,
    subscribePayoutLineNotifyEnabled: setting.subscribePayoutLineNotifyEnabled,
    weeklyOutOfStockReportEnabled: setting.weeklyOutOfStockReportEnabled,
  }

  const { updateLocalBusinessSettings, updatePayoutNotification } = useInject(
    ({ businessStore }) => ({
      updateLocalBusinessSettings: (newSetting: NewSettings) =>
        businessStore.updateLocalBusinessSettings(newSetting),
      updatePayoutNotification: businessStore.updatePayoutSettings,
    }),
  )

  const changeNewSetting = (field: string, isChecked: boolean) => {
    newSetting[field] = isChecked
  }

  const changePayoutSetting = (field: string, isChecked: boolean) => {
    newSetting[field] = isChecked
    updatePayoutNotification(setting.userId, setting.restaurantId, isChecked)
  }

  const updateSettings = useCallback(() => {
    updateLocalBusinessSettings(newSetting)
    toggleModal()
  }, [newSetting])

  return (
    <BaseModal isOpen>
      <TitleContainer>ปรับแต่งการแจ้งเตือน</TitleContainer>
      <DescriptionContainer>{setting.restaurantName}</DescriptionContainer>
      <SettingContainer>
        <Setting>
          <span>{NotificationType.DailySales}</span>
          <NotificationSwitch
            checked={newSetting.enableReceivingDailyReport}
            field="enableReceivingDailyReport"
            onChange={changeNewSetting}
          />
        </Setting>
        <Setting>
          <span>{NotificationType.MonthlySales}</span>
          <NotificationSwitch
            checked={newSetting.monthlyReportEnabled}
            field="monthlyReportEnabled"
            onChange={changeNewSetting}
          />
        </Setting>
        <Setting>
          <span>{NotificationType.Reviews}</span>
          <NotificationSwitch
            checked={newSetting.dailyReviewEnabled}
            field="dailyReviewEnabled"
            onChange={changeNewSetting}
          />
        </Setting>
        <Setting>
          <span>{NotificationType.Payout}</span>
          <NotificationSwitch
            checked={newSetting.subscribePayoutLineNotifyEnabled}
            field="subscribePayoutLineNotifyEnabled"
            onChange={changePayoutSetting}
          />
        </Setting>
        <Setting>
          <span>{NotificationType.OutOfStockMenus}</span>
          <NotificationSwitch
            checked={newSetting.weeklyOutOfStockReportEnabled}
            field="weeklyOutOfStockReportEnabled"
            onChange={changeNewSetting}
          />
        </Setting>
      </SettingContainer>
      <ButtonContainer>
        <Button
          size="large"
          responsive
          onClick={toggleModal}
          variant="outlined"
        >
          ยกเลิก
        </Button>
        <Button size="large" responsive onClick={updateSettings}>
          ตกลง
        </Button>
      </ButtonContainer>
    </BaseModal>
  )
}

export default NotificationSettingsModal
