import React, { useEffect } from 'react'
import ContentContainer from 'common/components/containers/ContentContainer'
import PageHeader from 'common/components/PageHeader'
import useInject from 'common/hooks/useInject'
import LinkingAccount from 'modules/user/components/LinkingAccount'
import useConnectionCondition from 'common/hooks/useConnectionCondition'

const AccountsPage = () => {
  useConnectionCondition({ requireAddFriend: true })
  const { accountsStatus, getAccountsStatus, accountLoading } = useInject(
    ({ accountsStore }) => ({
      accountsStatus: accountsStore.accountsStatus,
      getAccountsStatus: () => accountsStore.getAccountsStatus(),
      accountLoading: accountsStore.loading,
    }),
  )
  useEffect(() => {
    getAccountsStatus()
  }, [])

  return (
    <ContentContainer>
      <PageHeader>บัญชีของฉัน</PageHeader>
      {accountsStatus.map(({ fullNameType, user, type, loginPath }) => {
        return (
          <LinkingAccount
            key={type}
            fullNameType={fullNameType}
            loginPath={loginPath}
            user={user}
            loading={accountLoading}
          />
        )
      })}
    </ContentContainer>
  )
}

export default AccountsPage
