import Browser from 'common/lib/Browser'
import Timer from 'common/lib/Timer'
import { buildUrlScheme } from 'common/utils/urlUtils'
import qs from 'qs'

class App {
  openStore() {
    if (Browser.android?.check) {
      this.goToPlayStore()
    }
    if (Browser.ios?.check) {
      this.goToAppStore()
    }
  }

  goToPlayStore() {
    const queryString = qs.stringify({ id: 'com.wongnai.rms' })
    const url = `https://play.google.com/store/apps/details?${queryString}`
    window.location.href = url
  }

  goToAppStore() {
    const queryString = qs.stringify({ mt: 8 })
    const url = `https://itunes.apple.com/us/app/restaurant-management-system/id1157870935?${queryString}`
    window.location.href = url
  }

  deepLinkTo(path: string) {
    const HEART_BEAT_INTERVAL = 1000
    const maxCustomSchemeCheckDuration = HEART_BEAT_INTERVAL * 5 + 100

    let foregroundTimer: Timer
    let downloadTimer: Timer
    let lastInterval: number

    const cancelTimers = () => {
      foregroundTimer.cancel()
      downloadTimer.cancel()
    }

    const checkForeground = () => {
      if (isInBackground()) {
        cancelTimers()
      }
    }

    const isInBackground = () => {
      const now = new Date().getTime()
      const diff = now - lastInterval - HEART_BEAT_INTERVAL
      lastInterval = now
      return diff > HEART_BEAT_INTERVAL * 2
    }

    const downloadApp = () => {
      foregroundTimer.cancel()
      this.openStore()
    }

    if (Browser.mobile) {
      window.location.href = buildUrlScheme(path)

      lastInterval = new Date().getTime()
      foregroundTimer = new Timer({
        task: checkForeground,
        interval: HEART_BEAT_INTERVAL,
      })
      downloadTimer = new Timer({
        task: downloadApp,
        interval: maxCustomSchemeCheckDuration,
        maxTimes: 1,
      })

      window.addEventListener('pageshow', cancelTimers, { once: true })
      window.addEventListener('pagehide', cancelTimers, { once: true })
    }
  }
}

export default new App()
