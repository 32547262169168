import { useEffect } from 'react'
import useInject from 'common/hooks/useInject'

type Condition = {
  requireAddFriend?: boolean
  requireLogin?: boolean
}

function useConnectionCondition({ requireAddFriend, requireLogin }: Condition) {
  const {
    isInitialized,
    friendFlag,
    connectionStatus,
    goToVerifyFriendShip,
    goToAuthenticate,
  } = useInject(({ connectionStore }) => ({
    isInitialized: connectionStore.isInitialized,
    friendFlag: connectionStore.friendFlag,
    connectionStatus: connectionStore.connectionStatus,
    goToVerifyFriendShip: connectionStore.goToVerifyFriendShip,
    goToAuthenticate: connectionStore.goToAuthenticate,
  }))

  useEffect(() => {
    if (isInitialized) {
      if (requireAddFriend && !friendFlag) {
        goToVerifyFriendShip()
      } else if (requireLogin && !connectionStatus) {
        goToAuthenticate()
      }
    }
  }, [
    isInitialized,
    friendFlag,
    connectionStatus,
    requireAddFriend,
    requireLogin,
  ])
}

export default useConnectionCondition
