import React, { useCallback, useState, useEffect } from 'react'
import inputResolver from 'common/utils/resolvers/inputResolver'
import { isEmail } from 'common/utils/validationUtils'
import Input from '@wongnai/ui/components/Input'
import Button from '@wongnai/ui/components/Button'
import Text from '@wongnai/ui/components/Text'
import { fontFamilies } from 'common/styles/mixins'
import { spaces } from '@wongnai/ui/styles/mixins'
import Gap from '@wongnai/ui/components/Gap'
import statusResolver from 'common/utils/resolvers/statusResolver'
import { isBadRequest } from 'common/utils/statusUtils'
import useConnectionCondition from 'common/hooks/useConnectionCondition'
import { PageContainer, ButtonContainer } from './styled'
import { useModal } from './useModal'
import useResetPassword from './useResetPassword'

const ResetPasswordPage = () => {
  useConnectionCondition({ requireAddFriend: true })

  const { resetPassword, error } = useResetPassword()
  const [email, setEmail] = useState('')
  const { setErrorStatus } = useModal()

  const onClickReset = useCallback(() => {
    resetPassword(email)
  }, [email])

  useEffect(() => {
    const status = statusResolver(error)
    if (isBadRequest(status)) {
      setErrorStatus('badRequest')
    }
  }, [error])

  return (
    <PageContainer>
      <Gap className="responsive" type="vertical" size={spaces.normal}>
        <Text family={fontFamilies.highlight}>
          โปรดป้อนที่อยู่อีเมลที่ลงทะเบียนไว้เพื่อรีเซตรหัสผ่าน
        </Text>
        <Input
          className="fixed-height"
          placeholder="อีเมล"
          value={email}
          onChange={inputResolver(setEmail)}
        />
      </Gap>
      <ButtonContainer>
        <Button
          disabled={!isEmail(email)}
          onClick={onClickReset}
          size="large"
          responsive
        >
          ส่งคำขอรับรหัสผ่านใหม่
        </Button>
      </ButtonContainer>
    </PageContainer>
  )
}

export default ResetPasswordPage
