import styled from 'styled-components'
import Gap from '@wongnai/ui/components/Gap'
import Button from '@wongnai/ui/components/Button'
import { gray } from '@wongnai/ui/styles/colors'
import { fontSizes, fontFamilies } from 'common/styles/mixins'

export const ContentContainer = styled(Gap)`
  align-items: center;
`

export const TextContainer = styled(Gap)`
  text-align: center;
`

export const Title = styled.div`
  font-weight: bold;
  font-size: ${fontSizes.large2};
  font-family: ${fontFamilies.highlight};
`

export const Detail = styled.div`
  white-space: pre-wrap;
  color: ${gray[700]};
  font-size: ${fontSizes.small1};
  font-family: ${fontFamilies.highlight};
`

export const LinkButton = styled(Button)`
  width: 180px;
`
