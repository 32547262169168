import React, { ReactNode, HTMLAttributes } from 'react'
import { Wrapper } from './styled'

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
}

const ContentContainer = ({ children, className }: Props) => (
  <Wrapper className={className}>{children}</Wrapper>
)

export default ContentContainer
