import initLiff from 'client/initLiff'
import initVConsole from 'client/initVConsole'
import { initSentry } from 'core/sentry'
import WongnaiTracker from 'core/tracker/WongnaiTracker'

function beforeInit() {
  initSentry()
  WongnaiTracker.initTracker()
}

beforeInit()

initSentry()
initVConsole()
initLiff()
