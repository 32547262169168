import React, { ReactNode } from 'react'
import { TextWithAction } from './styled'

interface Props {
  children: ReactNode
  onClick: () => void
}

const ActionText = ({ children, onClick }: Props) => (
  <TextWithAction onClick={onClick}>{children}</TextWithAction>
)

export default ActionText
