import { observable, runInAction, action } from 'mobx'
import liff from '@line/liff'

import { BusinessSetting } from 'modules/business/types/business'
import FetchStateStore from 'common/stores/FetchStateStore'
import statusResolver from 'common/utils/resolvers/statusResolver'
import { isSuccess } from 'common/utils/statusUtils'
import {
  getBusinessSettings,
  updateBusinessSettings,
  updatePayoutNotification,
} from 'modules/business/api'
import { NewSettings } from 'modules/business/types/settings'

class BusinessStore extends FetchStateStore {
  rootStore: RootStore

  @observable
  businessSettings: BusinessSetting[] = []

  constructor(rootStore: RootStore) {
    super()
    this.rootStore = rootStore
  }

  @action
  async getBusinessSettings() {
    try {
      const response = await this.fetch(getBusinessSettings())
      runInAction(() => (this.businessSettings = response.data))
    } catch (e) {
      runInAction(() => (this.businessSettings = []))
    }
  }

  @action
  async updateBusinessSettings() {
    const res = await this.fetch(updateBusinessSettings(this.businessSettings))
    const status = statusResolver(res)
    if (isSuccess(status)) {
      liff.closeWindow()
    }
  }

  @action.bound
  updatePayoutSettings(userId: number, restaurantId: number, enabled: boolean) {
    this.fetch(
      updatePayoutNotification({
        userId,
        restaurantId,
        subscribePayoutLineNotifyEnabled: enabled,
      }),
    )
  }

  @action
  updateLocalBusinessSettings(newSetting: NewSettings) {
    const index = this.businessSettings.findIndex(
      setting => setting.id === newSetting.id,
    )
    if (index !== -1) {
      Object.keys(newSetting).forEach(key => {
        this.businessSettings[index][key] = newSetting[key]
      })
    }
  }
}

export default BusinessStore
