import WongnaiTracker from 'core/tracker/WongnaiTracker'

export function trackSupportButton() {
  WongnaiTracker.trackEvent({
    category: 'supportButton',
    action: 'call',
    name: 'woc',
    screenName: 'wocRegister',
  })
}

export function trackFailDialogModal() {
  WongnaiTracker.trackEvent({
    category: 'loginFailDialog',
    action: 'impress',
    name: 'woc',
    screenName: 'wocRegister',
  })
}
