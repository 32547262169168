import { Browser as BrowserType } from 'common/types'

function getWindowWidth(window: Window) {
  return (
    window.innerWidth ||
    window.document.documentElement.clientWidth ||
    window.document.body.clientWidth
  )
}

function createBrowser(window: Window) {
  // Used the same value as bootstrap variables.less
  const desktopWidth = 992
  const browser: BrowserType = {
    desktopWidth,
  }

  const ua = window.navigator.userAgent

  browser.mobile =
    getWindowWidth(window) < desktopWidth ||
    /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(ua)
  browser.android = {
    check: /(Android)/i.test(ua),
  }
  browser.ios = {
    check: /(iPhone|iPod|iPad)/i.test(ua),
    version: getIOSVersion(),
  }
  browser.mobileDevice = browser.android.check || browser.ios.check

  browser.windowWidth = getWindowWidth(window)

  function getIOSVersion() {
    const groups = /CPU .*OS (.*) like Mac OS/i.exec(ua)
    if (groups != null && groups.length > 1) {
      const version = parseVersion(groups[1])
      // eslint-disable-next-line
      return isFinite(version) ? version : -1
    }
    return -1
  }

  function parseVersion(version: string) {
    const versions = version.split('_')
    let versionStr = ''
    let prefix = ''
    for (let i = 0; i < versions.length; i++) {
      versionStr += prefix + versions[i]
      prefix = '.'
      if (i >= 1) {
        break
      }
    }
    return parseFloat(versionStr)
  }

  return browser
}

const Browser: BrowserType = createBrowser(window as Window)

export default Browser
