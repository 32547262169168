import styled from 'styled-components'
import { borders, spaces } from '@wongnai/ui/styles/mixins'
import { gray } from '@wongnai/ui/styles/colors'

export const AccountFrame = styled.div`
  border: 1px solid ${gray[300]};
  border-radius: ${borders.default};
  padding: ${spaces.large1};
  margin: 0 ${spaces.large2} ${spaces.large2};
  width: calc(100% - ${spaces.large2});

  display: flex;
  justify-content: space-between;
`
