import { action, observable } from 'mobx'
import history from 'core/router/history'
import FetchStateStore from 'common/stores/FetchStateStore'
import statusResolver from 'common/utils/resolvers/statusResolver'
import { isSuccess } from 'common/utils/statusUtils'
import {
  lineLogin,
  requestOtp,
  phoneLogin,
  resetPassword,
  linePosLogin,
} from 'modules/user/api'

class LoginStore extends FetchStateStore {
  rootStore: RootStore

  @observable
  phoneNumber = ''

  constructor(rootStore: RootStore) {
    super({ throwException: false })
    this.rootStore = rootStore
  }

  @action
  async loginWithEmail(email: string, password: string) {
    const res = await this.fetch(lineLogin(email, password))
    const status = statusResolver(res)
    if (isSuccess(status)) {
      this.handleSuccessLogin()
    }
  }

  @action
  async loginWithPhone(phoneNumber: string, code: string) {
    const res = await this.fetch(phoneLogin(phoneNumber, code))
    const status = statusResolver(res)
    if (isSuccess(status)) {
      this.handleSuccessLogin()
    }
  }

  @action
  async requestLoginOtp(phoneNumber: string) {
    const res = await this.fetch(requestOtp(phoneNumber))
    const status = statusResolver(res)
    if (isSuccess(status)) {
      history.push('/otp')
    }
  }

  @action
  async requestOtp(phoneNumber: string) {
    await this.fetch(requestOtp(phoneNumber))
  }

  @action
  async resetPassword(email: string) {
    const res = await this.fetch(resetPassword(email))
    const status = statusResolver(res)
    if (isSuccess(status)) {
      history.push('/login')
    }
  }

  @action
  setPhoneNumber(phoneNumber: string) {
    this.phoneNumber = phoneNumber
  }

  @action
  async loginPos(email: string, password: string) {
    const res = await this.fetch(linePosLogin(email, password))
    const status = statusResolver(res)
    if (isSuccess(status)) {
      this.handleSuccessLogin()
    }
  }

  handleSuccessLogin() {
    const { connectionStore } = this.rootStore.stores
    connectionStore.setConnectionStatus(true)
    history.push('/complete')
  }
}

export default LoginStore
