import React from 'react'
import { UserDetail } from 'modules/user/types/user'

import AccountLinkingManager from '../AccountLinkingManager'
import { AccountFrame } from './styled'
import AccountDetail from '../AccountDetail'

type Props = {
  fullNameType: string
  loginPath: string
  user?: UserDetail
  loading: boolean
}

const LinkingAccount = ({ fullNameType, user, loginPath, loading }: Props) => {
  return (
    <AccountFrame>
      <AccountDetail fullNameType={fullNameType} email={user?.email} />
      <AccountLinkingManager
        isLinked={!!user}
        loginPath={loginPath}
        loading={loading}
      />
    </AccountFrame>
  )
}

export default LinkingAccount
