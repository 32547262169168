import { observable, action } from 'mobx'
import { DefaultData, ModalData } from 'common/components/modals/types'

export const DEFAULT_DATA: DefaultData = {
  isOpen: false,
  modal: '',
  content: {
    title: '',
    description: '',
  },
}

class ModalStore {
  rootStore: RootStore

  @observable
  modalData: ModalData = DEFAULT_DATA

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action
  updateModalData(modalData: ModalData) {
    this.modalData = modalData
  }

  @action
  resetModalData() {
    this.modalData = DEFAULT_DATA
  }
}

export default ModalStore
