import styled, { css } from 'styled-components'
import { fontFamilies, fontSizes } from 'common/styles/mixins'
import { spaces } from '@wongnai/ui/styles/mixins'
import { gray, red, black } from '@wongnai/ui/styles/colors'
import Icon from '@wongnai/ui/components/BaseIcon'
import { styledWithProps } from '@wongnai/ui/styles'
import { HTMLAttributes } from 'react'
import DecoratableInput from '@wongnai/ui/components/DecoratableInput'

interface ResendOTPProps extends HTMLAttributes<HTMLSpanElement> {
  isActiveTimer: boolean
}

export const Title = styled.div`
  font-family: ${fontFamilies.highlight};
  margin-bottom: ${spaces.normal};
  font-size: ${fontSizes.large2};
  font-weight: bold;
`

export const Description = styled.div`
  margin-bottom: ${spaces.large2};
  white-space: pre-wrap;
  color: ${gray[700]};
  font-family: ${fontFamilies.highlight};
  text-align: center;
`

export const OTPInputContainer = styled.div`
  height: 72px;
  input {
    text-align: center;
    height: 40px;
  }
`

export const OTPInput = styled(DecoratableInput)`
  position: relative;
`

export const ResendOTP = styledWithProps<ResendOTPProps>(
  'span',
  ['isActiveTimer'],
  css`
    text-decoration: ${({ isActiveTimer }: ResendOTPProps) =>
      isActiveTimer ? 'none' : 'underline'};
    color: ${({ isActiveTimer }: ResendOTPProps) =>
      isActiveTimer ? gray[700] : black};
    pointer-events: ${({ isActiveTimer }: ResendOTPProps) =>
      isActiveTimer ? 'none' : 'auto'};
    margin-top: ${spaces.large3};
    font-family: ${fontFamilies.highlight};
    cursor: pointer;
  `,
)

export const Suffix = styled(Icon)`
  color: ${red[500]};
  position: absolute;
  right: 8px;
  top: 0;
  bottom: 0;
  margin: auto;
`
