import useInject from 'common/hooks/useInject'
import { ModalData } from 'common/components/modals/types'

function useModalAction() {
  return useInject(({ modalStore }) => ({
    updateModalData: (data: ModalData) => modalStore.updateModalData(data),
    resetModalData: () => modalStore.resetModalData(),
  }))
}

export default useModalAction
