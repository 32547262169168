import React, { ReactNode } from 'react'
import { Container } from './styled'

interface Props {
  children: ReactNode
  padding?: boolean
}

const CenterContainer = ({ children, padding }: Props) => (
  <Container padding={padding}>{children}</Container>
)

export default CenterContainer
