import React from 'react'
import ReactDOM from 'react-dom'
import liff from '@line/liff'

import config from 'core/config'
import App from 'client/App'
import logIfDev from 'common/utils/logIfDev'

async function initLiff() {
  try {
    await liff.init({
      liffId: config.liffId,
    })
    if (liff.isLoggedIn()) {
      ReactDOM.render(<App />, document.getElementById('root'))
    } else {
      const { pathname, href } = window.location
      liff.login(
        process.env.NODE_ENV === 'development'
          ? {
              redirectUri: `http://localhost:3000?path=${pathname}`,
            }
          : {
              redirectUri: href.replace('liff.state', 'path'),
            },
      )
    }
  } catch (e) {
    logIfDev(e)
  }
}

export default initLiff
