import { createGlobalStyle } from 'styled-components'
import { black, white } from '@wongnai/ui/styles/colors'
import fontStyles from 'common/styles/fonts'
import { fontFamilies, fontSizes } from 'common/styles/mixins'

function setupStyle() {
  return createGlobalStyle`
      body {
        ${fontStyles}
        font-family: ${fontFamilies.default};
        font-size: ${fontSizes.normal};
        background-color: ${white};
        color: ${black};
        margin: 0;
      }
      h1, h2, h3, h4, h5, h6, button {
        font-family: ${fontFamilies.highlight};
      }
      a {
        cursor: pointer;
        text-decoration: none;
      }
      *, :before, :after {
        box-sizing: border-box;
      }
  `
}

export default setupStyle
