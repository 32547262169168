import { action, observable, runInAction } from 'mobx'
import { getAccountsStatus } from 'modules/user/api'
import FetchStateStore from 'common/stores/FetchStateStore'
import { Account } from 'modules/user/types/user'
import { merge, groupBy } from 'lodash'

const INITIAL_STATUS: Account[] = [
  {
    type: 'RMS',
    fullNameType: 'Wongnai Merchant App',
    loginPath: '/login',
  },
  { type: 'POS', fullNameType: 'Wongnai POS', loginPath: '/pos' },
]

class AccountsStore extends FetchStateStore {
  rootStore: RootStore

  @observable
  accountsStatus = INITIAL_STATUS

  constructor(rootStore: RootStore) {
    super()
    this.rootStore = rootStore
  }

  @action
  async getAccountsStatus() {
    try {
      const { data } = await this.fetch(getAccountsStatus())
      const accountStatus = groupBy(data, 'type')
      const mergedAccount = INITIAL_STATUS.map(account =>
        merge(account, accountStatus[account.type][0]),
      )
      runInAction(() => (this.accountsStatus = mergedAccount))
    } catch (e) {
      runInAction(() => (this.accountsStatus = INITIAL_STATUS))
    }
  }
}

export default AccountsStore
