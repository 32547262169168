import React, { useEffect } from 'react'
import ContentContainer from 'common/components/containers/ContentContainer'
import SettingsList from 'modules/report/components/SettingsList'
import useInject from 'common/hooks/useInject'
import Button from '@wongnai/ui/components/Button'
import useConnectionCondition from 'common/hooks/useConnectionCondition'
import PageHeader from 'common/components/PageHeader'
import { ScrollContainer, ButtonContainer } from './styled'

const SettingsPage = () => {
  useConnectionCondition({ requireAddFriend: true, requireLogin: true })

  const { settings, getBusinessSettings, updateBusinessSettings } = useInject(
    ({ businessStore }) => ({
      settings: businessStore.businessSettings,
      getBusinessSettings: () => businessStore.getBusinessSettings(),
      updateBusinessSettings: () => businessStore.updateBusinessSettings(),
    }),
  )

  useEffect(() => {
    getBusinessSettings()
  }, [])

  return (
    <ContentContainer>
      <ScrollContainer>
        <PageHeader>ตั้งค่าการแจ้งเตือน</PageHeader>
        <SettingsList settings={settings} />
      </ScrollContainer>
      <ButtonContainer>
        <Button size="large" responsive onClick={updateBusinessSettings}>
          บันทึก
        </Button>
      </ButtonContainer>
    </ContentContainer>
  )
}

export default SettingsPage
