import useInject from 'common/hooks/useInject'

function useResetPassword() {
  return useInject(({ loginStore }) => ({
    resetPassword: (email: string) => loginStore.resetPassword(email),
    error: loginStore.error,
  }))
}

export default useResetPassword
