export default {
  liffId: '1653757360-8bER2xZV',
  oaDeepLink: 'line://ti/p/@459cfbls',
  appUrlScheme: 'rms://',
  api: {
    endpoint: 'https://woc-chatbot-rms.wongnai.com',
  },
  sentry: {
    dsn:
      'https://4f73b4e11a324bbaa2d93f9a6843f9a4@o454972.ingest.sentry.io/5464313',
  },
  tracker: {
    wongnai: {
      url: 'https://scribe.wongnai.com/scribe',
      siteId: 'wn',
    },
  },
  url: {
    pos: 'https://www.wongnai.com/pos',
  },
}
