import { useContext } from 'react'
import { MobXProviderContext, useObserver } from 'mobx-react'
import { Stores } from 'common/stores/RootStore'

function useInject<Props>(dataSelector: (stores: Stores) => Props) {
  const value = useContext(MobXProviderContext)
  return useObserver(() => dataSelector(value))
}

export default useInject
