import styled from 'styled-components'
import { primary } from '@wongnai/ui/styles/theme'
import { fontSizes, fontFamilies } from 'common/styles/mixins'

export const TextWithAction = styled.div`
  color: ${primary[500]};
  text-decoration: underline;
  font-size: ${fontSizes.small1};
  font-family: ${fontFamilies.highlight};
  cursor: pointer;
`
