import config from 'core/config'

export function getUrlWithoutLeadingSlash(url?: string) {
  if (!url) {
    return ''
  }
  if (url.startsWith('/')) {
    return url.substring(1)
  }
  return url
}

export function buildUrlScheme(path: string) {
  return `${config.appUrlScheme}${getUrlWithoutLeadingSlash(path)}`
}
