import { css } from 'styled-components'
import { styledWithProps } from '@wongnai/ui/styles'
import { spaces } from '@wongnai/ui/styles/mixins'

interface Props {
  padding?: boolean
}

export const Container = styledWithProps<Props>(
  'div',
  ['padding'],
  css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: ${({ padding }: Props) => (padding ? spaces.large6 : 0)};
  `,
)
