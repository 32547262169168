import React, { useCallback, useState } from 'react'
import Switch from '@wongnai/ui/components/Switch'
import { orange } from '@wongnai/ui/styles/colors'

interface SwitchProps {
  checked: boolean
  field: string
  onChange: (field: string, isChecked: boolean) => void
}

const NotificationSwitch = ({ checked, field, onChange }: SwitchProps) => {
  const [isChecked, setCheck] = useState(checked)

  const toggleCheck = useCallback(() => {
    setCheck(!isChecked)
    onChange(field, !isChecked)
  }, [isChecked, setCheck])

  return (
    <Switch
      checked={isChecked}
      baseColor={orange[50]}
      handleColor={orange[500]}
      onChange={toggleCheck}
      disabled={false}
    />
  )
}

export default NotificationSwitch
