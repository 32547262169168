import React from 'react'
import SubmitModal from 'common/components/modals/SubmitModal'
import ActionModal from 'common/components/modals/ActionModal'
import useInject from 'common/hooks/useInject'
import {
  SubmitModalData,
  ActionModalData,
} from 'common/components/modals/types'

function useModal() {
  return useInject(({ modalStore }) => modalStore.modalData)
}

function ModalSelector() {
  const data = useModal()
  const { modal } = data

  if (modal === 'submit') {
    const {
      isOpen,
      content,
      submitText,
      onClickClose,
      onImpression,
    } = data as SubmitModalData
    return (
      <SubmitModal
        isOpen={isOpen}
        title={content.title}
        description={content.description}
        submitText={submitText}
        onClickSubmit={onClickClose}
        onImpression={onImpression}
      />
    )
  } else if (modal === 'action') {
    const {
      isOpen,
      content,
      closeText,
      actionText,
      onClickClose,
      onClickAction,
      onImpression,
    } = data as ActionModalData
    return (
      <ActionModal
        isOpen={isOpen}
        title={content.title}
        description={content.description}
        closeText={closeText}
        actionText={actionText}
        onClickClose={onClickClose}
        onClickAction={onClickAction}
        onImpression={onImpression}
      />
    )
  }

  return null
}

export default ModalSelector
