import styled from 'styled-components'
import ContentContainer from 'common/components/containers/ContentContainer'
import { spaces } from '@wongnai/ui/styles/mixins'
import { gray } from '@wongnai/ui/styles/colors'

export const Container = styled(ContentContainer)`
  padding: 0 ${spaces.large2};
  > * {
    &:only-child {
      border-bottom: 1px solid ${gray[300]};
      border-top: 1px solid ${gray[300]};
    }
    &:not(:last-child) {
      border-top: 1px solid ${gray[300]};
    }
    &:last-child {
      border-top: 1px solid ${gray[300]};
      border-bottom: 1px solid ${gray[300]};
    }
  }
`
