import styled from 'styled-components'
import { spaces, fontSizes } from '@wongnai/ui/styles/mixins'
import { gray, black } from '@wongnai/ui/styles/colors'
import { fontFamilies } from 'common/styles/mixins'

export const Img = styled.img`
  margin-top: ${spaces.large6};
  margin-bottom: ${spaces.large4};
`

export const Header = styled.div`
  font-family: ${fontFamilies.highlight};
  font-size: ${fontSizes.large2};
  color: ${black};
  text-align: center;
`

export const Description = styled.div`
  font-family: ${fontFamilies.highlight};
  color: ${gray[700]};
  text-align: center;
`
