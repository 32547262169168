import React from 'react'

import Button, { Size } from '@wongnai/ui/components/Button'
import { Link } from 'react-router-dom'
import Spinner from '@wongnai/ui/components/Spinner'

type Props = {
  isLinked: boolean
  loginPath: string
  loading: boolean
}

const AccountLinkingManager = ({ isLinked, loginPath, loading }: Props) => {
  if (loading) {
    return <Spinner size="xs" />
  }

  if (!isLinked) {
    return (
      <Link to={loginPath}>
        <Button size={Size.LARGE}>เชื่อมต่อ</Button>
      </Link>
    )
  }
  // isLinked case is for future feature (use onUnlink in useModal)
  return null
}

export default AccountLinkingManager
