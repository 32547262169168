import { useCallback } from 'react'
import { ModalData } from 'common/components/modals/types'
import useModalAction from 'common/hooks/useModalAction'

type Status = 'badRequest'

const MODAL_DATA: Record<string, ModalData> = {
  badRequest: {
    isOpen: true,
    modal: 'submit',
    submitText: 'กลับ',
    content: {
      title: 'ไม่พบบัญชีที่ใช้อีเมลนี้ในระบบ',
      description: '',
    },
  },
}

export const useModal = () => {
  const { updateModalData, resetModalData } = useModalAction()

  const setErrorStatus = useCallback((status: Status) => {
    if (status === 'badRequest') {
      updateModalData({
        ...MODAL_DATA.badRequest,
        onClickClose: resetModalData,
      })
    } else {
      resetModalData()
    }
  }, [])

  return { setErrorStatus }
}
