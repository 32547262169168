import React from 'react'
import SettingsItem from 'modules/report/components/SettingsItem'
import { BusinessSetting } from 'modules/business/types/business'
import { Container } from './styled'

interface Props {
  settings: BusinessSetting[]
}

const SettingsList = ({ settings }: Props) => {
  return (
    <Container>
      {settings.map((setting, index) => (
        <SettingsItem setting={setting} key={index} />
      ))}
    </Container>
  )
}

export default SettingsList
