import { parsePhoneNumberFromString } from 'libphonenumber-js'

export function isPhoneNumber(phoneNumber: string) {
  const number = parsePhoneNumberFromString(phoneNumber, 'TH')
  return number?.isValid()
}

export function isEmail(email: string) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  )
}
