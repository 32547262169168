import React from 'react'
import Text from '@wongnai/ui/components/Text'
import { gray } from '@wongnai/ui/styles/colors'
import Wongnai from '@wongnai/ui/components/Logo/wongnai.svg'
import { fontFamilies } from 'common/styles/mixins'
import { spaces } from '@wongnai/ui/styles/mixins'
import { Footer } from './styled'

const LoginFooter = () => (
  <Footer size={spaces.small1}>
    <Text family={fontFamilies.highlight} color={gray[700]} lineHeight="28px">
      By
    </Text>
    <img src={Wongnai} alt="wongnai" width="80" height="30" />
  </Footer>
)

export default LoginFooter
