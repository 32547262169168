import React from 'react'
import Button from '@wongnai/ui/components/Button'
import BaseModal, { BaseModalProps } from 'common/components/modals/BaseModal'
import { TitleContainer, DescriptionContainer, SubmitContainer } from './styled'

interface Props extends BaseModalProps {
  title: string
  description: string
  isOpen: boolean
  submitText: string
  onClickSubmit: () => void
}

const SubmitModal = ({
  className,
  title,
  description,
  isOpen,
  submitText,
  onClickSubmit,
  onImpression,
}: Props) => (
  <BaseModal className={className} isOpen={isOpen} onImpression={onImpression}>
    <TitleContainer>{title}</TitleContainer>
    <DescriptionContainer>{description}</DescriptionContainer>
    <SubmitContainer>
      <Button variant="flatColor" onClick={onClickSubmit} responsive>
        {submitText}
      </Button>
    </SubmitContainer>
  </BaseModal>
)

export default SubmitModal
