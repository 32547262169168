export const lineHeights = {
  large5: '44px',
  large4: '40px',
  large3: '36px',
  large2: '32px',
  large1: '28px',
  normal: '24px',
  small1: '20px',
  small2: '16px',
  small3: '14px',
}

export const iconSizes = {
  extraLarge2: '295px',
  extraLarge1: '205px',
  large2: '64px',
  large1: '32px',
  medium: '24px',
  normal: '16px',
}

export const borders = {
  default: '4px',
  extraRound: '16px',
  circle: '50%',
}
