import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import qs from 'qs'
import liff from '@line/liff'
import { Request } from './types'

class APIClient {
  client: AxiosInstance

  constructor(options: AxiosRequestConfig) {
    this.client = axios.create(options)
  }

  serializeParams(params: Record<string, any>) {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  }

  fetch({ headers = {}, method, body = {}, params = {}, path }: Request) {
    const request = {
      method,
      headers,
      params,
      url: path,
      paramsSerializer: this.serializeParams,
      data: '',
    } as AxiosRequestConfig
    request.headers.Authorization = `Bearer ${liff.getAccessToken()}`
    if (['post', 'put', 'patch'].includes(method)) {
      request.data = body
    }
    return this.client(request)
  }
}

export default APIClient
