import styled from 'styled-components'
import { fontFamilies } from 'common/styles/mixins'
import { spaces } from '@wongnai/ui/styles/mixins'
import Text from '@wongnai/ui/components/Text'

export const ItemContainer = styled.div`
  width: 100%;
  padding-top: ${spaces.large2};
`

export const BusinessName = styled(Text)`
  font-family: ${fontFamilies.highlight};
  margin-bottom: ${spaces.normal};
  font-weight: bold;
`
