import styled from 'styled-components'
import { fontFamilies } from 'common/styles/mixins'
import { spaces } from '@wongnai/ui/styles/mixins'
import { gray } from '@wongnai/ui/styles/colors'

export const HeaderContainer = styled.div`
  text-align: center;
  margin-top: ${spaces.large6};
  margin-bottom: ${spaces.large3};
`

export const Header = styled.div`
  font-family: ${fontFamilies.highlight};
  color: ${gray[700]};
`
