import useInject from 'common/hooks/useInject'

function usePhoneAuth() {
  return useInject(({ loginStore }) => ({
    phoneNumber: loginStore.phoneNumber,
    error: loginStore.error,
    loginWithPhone: (phoneNumber: string, code: string) =>
      loginStore.loginWithPhone(phoneNumber, code),
    requestOtp: (phoneNumber: string) => loginStore.requestOtp(phoneNumber),
  }))
}

export default usePhoneAuth
