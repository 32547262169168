import React, { useState, useCallback, useMemo, Fragment } from 'react'
import Input from '@wongnai/ui/components/Input'
import DecoratableInput from '@wongnai/ui/components/DecoratableInput'
import Button from '@wongnai/ui/components/Button'
import inputResolver from 'common/utils/resolvers/inputResolver'
import VisibleIcon from 'common/img/visible-icon.svg'
import InvisibleIcon from 'common/img/invisible-icon.svg'
import { spaces } from '@wongnai/ui/styles/mixins'
import { isEmail, isPhoneNumber } from 'common/utils/validationUtils'
import history from 'core/router/history'
import { FormContainer, Header, ForgotPassword } from './styled'
import useLoginAction from './useLoginAction'

const renderSuffix = (visible: boolean, toggleVisible: () => void) =>
  visible ? (
    <img src={VisibleIcon} alt="visible-icon" onClick={toggleVisible} />
  ) : (
    <img src={InvisibleIcon} alt="invisible-icon" onClick={toggleVisible} />
  )

const LoginForm = () => {
  const { loginWithEmail, requestLoginOtp, setPhoneNumber } = useLoginAction()
  const [userId, setUserId] = useState('')
  const [password, setPassword] = useState('')
  const [visible, setVisible] = useState(false)

  const toggleVisible = useCallback(() => {
    setVisible(!visible)
  }, [visible])

  const onClickLogin = useCallback(async () => {
    if (isEmail(userId)) {
      await loginWithEmail(userId, password)
    } else {
      setPhoneNumber(userId)
      requestLoginOtp(userId)
    }
  }, [userId, password])

  const disabled = useMemo(() => {
    if (isEmail(userId)) {
      return password === ''
    } else {
      return !isPhoneNumber(userId)
    }
  }, [userId, password])

  const goToResetPassword = useCallback(() => {
    history.push('/reset-password')
  }, [])

  return (
    <FormContainer type="vertical" size={spaces.large1}>
      <Header>{'เข้าสู่ระบบด้วย\nWongnai Merchant App (RMS)'}</Header>
      <Input
        className="fixed-height"
        placeholder="อีเมล หรือเบอร์โทรศัพท์"
        onChange={inputResolver(setUserId)}
      />
      {isEmail(userId) && (
        <Fragment>
          <DecoratableInput
            className="fixed-height"
            suffix={renderSuffix(visible, toggleVisible)}
            type={visible ? 'text' : 'password'}
            placeholder="รหัสผ่าน"
            onChange={inputResolver(setPassword)}
          />
          <ForgotPassword onClick={goToResetPassword}>
            ลืมรหัสผ่าน?
          </ForgotPassword>
        </Fragment>
      )}
      <Button size="large" onClick={onClickLogin} disabled={disabled}>
        เข้าสู่ระบบ
      </Button>
    </FormContainer>
  )
}

export default LoginForm
