import styled from 'styled-components'
import { fontFamilies, fontSizes } from 'common/styles/mixins'
import { spaces } from '@wongnai/ui/styles/mixins'

export const Header = styled.div`
  text-align: center;
`

export const Logo = styled.img`
  margin-top: ${spaces.large1};
  margin-bottom: ${spaces.large2};
`

export const Title = styled.div`
  font-family: ${fontFamilies.highlight};
  font-size: ${fontSizes.large1};
  font-weight: bold;
  margin-bottom: ${spaces.large2};
`
