import { isFunction, isNil } from 'lodash'
import { TimerAttributes } from 'common/types'

class Timer {
  count = 0

  attributes: TimerAttributes

  handler: number | null = null

  constructor(attributes: TimerAttributes) {
    this.attributes = attributes

    if (!attributes.manualStart) {
      this.start()
    }
  }

  start() {
    if (!this.handler) {
      this.count = 0
      this.next()
    }
  }

  next() {
    this.handler = window.setTimeout(this.handle, this.attributes.interval)
  }

  handle = () => {
    if (!isNil(this.attributes.task) && isFunction(this.attributes.task)) {
      this.attributes.task()

      this.count++
      if (
        isNil(this.attributes.maxTimes) ||
        this.attributes.maxTimes > this.count
      ) {
        this.next()
      }
    }
  }

  cancel() {
    if (this.handler) {
      clearTimeout(this.handler)
      this.handler = null
    }
  }
}

export default Timer
