import React, { useEffect } from 'react'
import { RouteProps, Route } from 'react-router-dom'
import ErrorBoundary from 'core/error/components/ErrorBoundary'
import WongnaiTracker from 'core/tracker/WongnaiTracker'

const RoutePath = ({ path, ...rest }: RouteProps) => {
  useEffect(() => {
    WongnaiTracker.trackView(path as string)
  }, [path])

  return (
    <ErrorBoundary key={path as string}>
      <Route path={path} {...rest} />
    </ErrorBoundary>
  )
}

export default RoutePath
