import React, { useCallback } from 'react'
import liff from '@line/liff'

import CenterContainer from 'common/components/containers/CenterContainer'
import CircleCorrectIcon from 'common/img/correct-outlined.svg'
import ActionText from 'common/components/ActionText'
import useConnectionCondition from 'common/hooks/useConnectionCondition'
import history from 'core/router/history'
import { Icon, Title, Detail, CloseAppButton } from './styled'
import { trackSettingButton, trackCloseButton } from './trackUtils'

const CompletePage = () => {
  useConnectionCondition({ requireAddFriend: true, requireLogin: true })

  const onClickSetting = useCallback(() => {
    trackSettingButton()
    history.push('/settings')
  }, [])

  const onClickCloseApp = useCallback(() => {
    trackCloseButton()
    liff.closeWindow()
  }, [])

  return (
    <CenterContainer padding>
      <Icon src={CircleCorrectIcon} alt="" width="40" height="40" />
      <Title>สมัครรับบริการสำเร็จ</Title>
      <Detail>คุณจะได้รับแจ้งเตือนสรุปยอดขายทุกวันหลังร้านปิด</Detail>
      <CloseAppButton onClick={onClickCloseApp} size="large">
        เริ่มใช้งาน
      </CloseAppButton>
      <ActionText onClick={onClickSetting}>แก้ไขเวลารับแจ้งเตือน</ActionText>
    </CenterContainer>
  )
}

export default CompletePage
