import { captureException, init } from '@sentry/browser'

import config from 'core/config'

export function initSentry() {
  if (process.env.NODE_ENV === 'production') {
    init({
      dsn: config.sentry.dsn,
      environment: process.env.REACT_APP_BUILD_ENV,
      release: process.env.REACT_APP_DEPLOY_VERSION,
    })
  }
}

export function catchError(error: Error) {
  captureException(error)
}
