import config from 'core/config'
import { useCallback } from 'react'
import { ModalData } from 'common/components/modals/types'
import useModalAction from 'common/hooks/useModalAction'
import { trackFailDialogModal } from './trackUtils'

type Status = 'unauthorized' | 'serverError'

const MODAL_DATA: Record<string, ModalData> = {
  unauthorized: {
    isOpen: true,
    modal: 'action',
    closeText: 'กลับ',
    actionText: 'สมัครบัญชีใหม่',
    content: {
      title: 'อีเมลหรือรหัสผ่านไม่ถูกต้อง',
      description:
        'Wongnai Owner Connect เป็นบริการเสริม สำหรับ Wongnai Pos คุณจำเป็นต้องมีบัญชีของ Wongnai Pos ก่อน',
    },
  },
  serverError: {
    isOpen: true,
    modal: 'submit',
    submitText: 'ตกลง',
    content: {
      title: 'เกิดข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง',
      description: 'หรือติดต่อฝ่ายบริการลูกค้า 02-821-5788',
    },
  },
}

export const useModal = () => {
  const { updateModalData, resetModalData } = useModalAction()

  const setErrorStatus = useCallback((status: Status) => {
    if (status === 'unauthorized') {
      updateModalData({
        ...MODAL_DATA.unauthorized,
        onClickClose: resetModalData,
        onClickAction: () => {
          resetModalData()
          window.location.href = config.url.pos
        },
        onImpression: trackFailDialogModal,
      })
    } else if (status === 'serverError') {
      updateModalData({
        ...MODAL_DATA.serverError,
        onClickClose: resetModalData,
      })
    } else {
      resetModalData()
    }
  }, [])

  return { setErrorStatus }
}
