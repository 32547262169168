import React from 'react'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'mobx-react'
import Routes from 'modules/routes'
import { themes } from '@wongnai/ui/styles/theme'
import setupStyle from 'common/styles/setup'
import FullPageContainer from 'common/components/containers/FullPageContainer'
import RootStore from 'common/stores/RootStore'
import ModalSelector from 'common/components/modals/ModalSelector'

const GlobalStyleSheet = setupStyle()

const rootStore = new RootStore()

const App = () => {
  rootStore.stores.connectionStore.initConnection()

  return (
    <Provider {...rootStore.stores}>
      <GlobalStyleSheet />
      <ThemeProvider theme={themes.wongnai}>
        <FullPageContainer>
          <Routes />
          <ModalSelector />
        </FullPageContainer>
      </ThemeProvider>
    </Provider>
  )
}

export default App
