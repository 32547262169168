import React from 'react'
import Text from '@wongnai/ui/components/Text'
import { fontFamilies } from 'common/styles/mixins'
import { gray } from '@wongnai/ui/styles/colors'
import { fontSizes, lineHeights } from '@wongnai/ui/styles/mixins'

type Props = {
  fullNameType: string
  email?: string
}

const AccountDetail = ({ email, fullNameType }: Props) => (
  <div>
    <Text as="h4" weight="bold" margin="0" lineHeight={lineHeights.normal}>
      {fullNameType}
    </Text>
    {email && (
      <Text
        as="p"
        family={fontFamilies.highlight}
        color={gray[700]}
        size={fontSizes.small1}
        lineHeight={lineHeights.small1}
        margin="0"
      >
        {email}
      </Text>
    )}
  </div>
)

export default AccountDetail
