export function isSuccess(status: number) {
  return status === 200
}

export function isNoContent(status: number) {
  return status === 204
}

export function isBadRequest(status: number) {
  return status === 400
}

export function isUnauthorized(status: number) {
  return status === 401
}

export function isNotFound(status: number) {
  return status === 404
}

export function isServerError(status: number) {
  return status === 500
}
