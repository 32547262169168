import styled from 'styled-components'
import { spaces, fontSizes, lineHeights } from '@wongnai/ui/styles/mixins'
import { gray } from '@wongnai/ui/styles/colors'
import { fontFamilies } from 'common/styles/mixins'

export const Container = styled.div`
  padding: ${spaces.large1} ${spaces.small2} 0 ${spaces.small2};
  display: flex;
  align-items: flex-start;
`

interface MiddleTextProps extends React.HTMLProps<HTMLDivElement> {
  $borderSize: string
}

export const MiddleText = styled.div`
  padding-bottom: ${spaces.large1};
  border-bottom: ${({ $borderSize }: MiddleTextProps) =>
    `${$borderSize} solid ${gray[300]}`};
  flex-grow: 1;
`
export const SettingName = styled.div`
  font-family: ${fontFamilies.highlight};
  font-size: ${fontSizes.small1};
  line-height: ${lineHeights.small1};
`

export const RadioCheckIcon = styled.img`
  margin-right: ${spaces.small1};
`

export const SettingIcon = styled.img`
  margin-right: ${spaces.normal};
`

export const SettingDescription = styled.div`
  display: flex;
  align-items: baseline;
  font-family: ${fontFamilies.highlight};
  font-size: ${fontSizes.small2};
  color: ${gray[500]};
  line-height: ${lineHeights.small2};
`
