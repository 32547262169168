import { client } from 'core/api'
import {
  PayoutNotificationDetail,
  UserSettingDetail,
} from 'modules/business/types/business'

export const getBusinessSettings = () => {
  return client.fetch({
    method: 'get',
    path: '/line/user-settings',
  })
}

export const updateBusinessSettings = (
  userSettingDetails: UserSettingDetail[],
) => {
  return client.fetch({
    method: 'put',
    path: '/line/user-settings',
    body: {
      userSettingDetails,
    },
  })
}

export const updatePayoutNotification = ({
  userId,
  restaurantId,
  subscribePayoutLineNotifyEnabled,
}: PayoutNotificationDetail) => {
  return client.fetch({
    method: 'patch',
    path: '/line/user-settings/payout-line-notify',
    params: {
      userId,
      restaurantId,
    },
    body: {
      subscribePayoutLineNotify: subscribePayoutLineNotifyEnabled,
    },
  })
}
