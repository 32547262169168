import React, { useState, useCallback } from 'react'
import RadioCheckIconImage from 'common/img/radio-check-icon.svg'
import ChevronRight from 'common/img/chevron-right.svg'
import { BusinessSetting } from 'modules/business/types/business'
import NotificationSettingsModal from 'modules/report/components/modals/NotificationSettingsModal'
import DailyReportSendingTimeModal from 'modules/report/components/modals/DailyReportSendingTimeModal'
import useDescriptionFromType, {
  SettingsType,
} from 'common/hooks/useDescriptionFromSetting'
import {
  Container,
  MiddleText,
  SettingName,
  SettingDescription,
  RadioCheckIcon,
  SettingIcon,
} from './styled'

interface Props {
  setting: BusinessSetting
  name: string
  settingIcon: string
  borderSize: string
  type: SettingsType
}

interface ModalOptions {
  type: SettingsType
  isOpen: boolean
  setting: BusinessSetting
  toggleModal: () => void
}

const SettingsModal = ({
  type,
  isOpen,
  setting,
  toggleModal,
}: ModalOptions) => {
  if (!isOpen) {
    return null
  }

  if (type === 'notificationSetting') {
    return (
      <NotificationSettingsModal setting={setting} toggleModal={toggleModal} />
    )
  } else if (type === 'dailyReportSendingTime') {
    return (
      <DailyReportSendingTimeModal
        setting={setting}
        toggleModal={toggleModal}
      />
    )
  }
  return null
}

const SettingsOption = ({
  type,
  name,
  settingIcon,
  borderSize,
  setting,
}: Props) => {
  const description = useDescriptionFromType({ type, setting })

  const [isOpen, setIsOpen] = useState(false)

  const toggleModal = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen, setIsOpen])

  return (
    <>
      <SettingsModal {...{ type, isOpen, setting, toggleModal }} />
      <Container onClick={toggleModal}>
        <SettingIcon src={settingIcon} alt="Bell Icon" />
        <MiddleText $borderSize={borderSize}>
          <SettingName>{name}</SettingName>
          <SettingDescription>
            <RadioCheckIcon src={RadioCheckIconImage} alt="Radio Check Icon" />
            {description}
          </SettingDescription>
        </MiddleText>
        <img src={ChevronRight} alt="Right Arrow" />
      </Container>
    </>
  )
}

export default SettingsOption
