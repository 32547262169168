import React, { useEffect } from 'react'
import LoginFooter from 'modules/user/components/LoginFooter'
import LoginForm from 'modules/user/components/LoginPosForm'
import LoginHeader from 'modules/user/components/LoginHeader'
import useInject from 'common/hooks/useInject'
import { Stores } from 'common/stores/RootStore'
import statusResolver from 'common/utils/resolvers/statusResolver'
import {
  isUnauthorized,
  isServerError,
  isBadRequest,
} from 'common/utils/statusUtils'
import useConnectionCondition from 'common/hooks/useConnectionCondition'
import { useModal } from './useModal'
import { PageContainer, PhoneLink } from './styled'

const LoginPage = () => {
  useConnectionCondition({ requireAddFriend: true })

  const { error } = useInject(({ loginStore }: Stores) => ({
    error: loginStore.error,
  }))

  const { setErrorStatus } = useModal()

  useEffect(() => {
    const status = statusResolver(error)
    if (isUnauthorized(status) || isBadRequest(status)) {
      setErrorStatus('unauthorized')
    } else if (isServerError(status)) {
      setErrorStatus('serverError')
    }
  }, [error])

  return (
    <PageContainer>
      <LoginHeader />
      <LoginForm />
      <PhoneLink href="tel:028215788">ติดต่อฝ่ายบริการลูกค้า</PhoneLink>
      <LoginFooter />
    </PageContainer>
  )
}

export default LoginPage
