import { css } from 'styled-components'

export default css`
  @font-face {
    font-family: 'DB Heavent Now';
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    src: local('DB Heavent Now'),
      url(${require('common/styles/fonts/DBHeaventNow/DBHeaventNow-Bold.woff2')})
        format('woff2'),
      url(${require('common/styles/fonts/DBHeaventNow/DBHeaventNow-Bold.woff')})
        format('woff'),
      url(${require('common/styles/fonts/DBHeaventNow/DBHeaventNow-Bold.ttf')})
        format('truetype');
  }

  @font-face {
    font-family: 'DB Heavent Now';
    font-weight: bold;
    font-style: italic;
    font-display: swap;
    src: local('DB Heavent Now'),
      url(${require('common/styles/fonts/DBHeaventNow/DBHeaventNow-BoldIt.woff2')})
        format('woff2'),
      url(${require('common/styles/fonts/DBHeaventNow/DBHeaventNow-BoldIt.woff')})
        format('woff'),
      url(${require('common/styles/fonts/DBHeaventNow/DBHeaventNow-BoldIt.ttf')})
        format('truetype');
  }

  @font-face {
    font-family: 'DB Heavent Now';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: local('DB Heavent Now'),
      url(${require('common/styles/fonts/DBHeaventNow/DBHeaventNow.woff2')})
        format('woff2'),
      url(${require('common/styles/fonts/DBHeaventNow/DBHeaventNow.woff')})
        format('woff'),
      url(${require('common/styles/fonts/DBHeaventNow/DBHeaventNow.ttf')})
        format('truetype');
  }

  @font-face {
    font-family: 'DB Heavent Now';
    font-weight: normal;
    font-style: italic;
    font-display: swap;
    src: local('DB Heavent Now'),
      url(${require('common/styles/fonts/DBHeaventNow/DBHeaventNow-Italic.woff2')})
        format('woff2'),
      url(${require('common/styles/fonts/DBHeaventNow/DBHeaventNow-Italic.woff')})
        format('woff'),
      url(${require('common/styles/fonts/DBHeaventNow/DBHeaventNow-Italic.ttf')})
        format('truetype');
  }
`
