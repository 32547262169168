import React from 'react'
import { Router, Switch } from 'react-router-dom'
import RoutePath from 'core/router/components/RoutePath'
import history from 'core/router/history'
import RootPage from 'modules/root/pages/RootPage'
import OTPPage from 'modules/user/pages/OTPPage'
import LoginPage from 'modules/user/pages/LoginPage'
import OAVerifyPage from 'modules/user/pages/OAVerifyPage'
import CompletePage from 'modules/report/pages/CompletePage'
import ResetPasswordPage from 'modules/user/pages/ResetPasswordPage'
import SettingsPage from 'modules/report/pages/SettingsPage'
import PosLoginPage from 'modules/user/pages/LoginPosPage'
import AccountsPage from 'modules/user/pages/AccountsPage'

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <RoutePath exact path="/" component={RootPage} />
        <RoutePath exact path="/verify" component={OAVerifyPage} />
        <RoutePath exact path="/login" component={LoginPage} />
        <RoutePath exact path="/complete" component={CompletePage} />
        <RoutePath exact path="/settings" component={SettingsPage} />
        <RoutePath exact path="/accounts" component={AccountsPage} />
        <RoutePath exact path="/otp" component={OTPPage} />
        <RoutePath exact path="/reset-password" component={ResetPasswordPage} />
        <RoutePath exact path="/pos" component={PosLoginPage} />
      </Switch>
    </Router>
  )
}

export default Routes
