import React, { HTMLAttributes } from 'react'
import { BusinessSetting } from 'modules/business/types/business'
import BellIcon from 'common/img/bell-icon.svg'
import ClockIcon from 'common/img/clock-icon.svg'
import SettingsOption from 'modules/report/components/SettingsOption'
import { ItemContainer, BusinessName } from './styled'

interface Props extends HTMLAttributes<HTMLDivElement> {
  setting: BusinessSetting
}

const SettingsItem = ({ className, setting }: Props) => {
  return (
    <ItemContainer className={className}>
      <BusinessName as="div" ellipsis={1}>
        {setting.restaurantName}
      </BusinessName>
      <SettingsOption
        type="notificationSetting"
        name="ปรับแต่งการแจ้งเตือน"
        settingIcon={BellIcon}
        borderSize="1px"
        setting={setting}
      />
      <SettingsOption
        type="dailyReportSendingTime"
        name="เลือกเวลาตัดรอบรายงานประจำวัน"
        settingIcon={ClockIcon}
        borderSize="0px"
        setting={setting}
      />
    </ItemContainer>
  )
}

export default SettingsItem
