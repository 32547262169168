import styled from 'styled-components'
import RelativeContainer from '@wongnai/ui/components/RelativeContainer'
import { getVh } from '@wongnai/ui/styles/utils/viewport'

const FullPageContainer = styled(RelativeContainer)`
  display: flex;
  min-height: ${getVh(100)};
  overflow: hidden;
`

export default FullPageContainer
