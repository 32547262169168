import React, { useEffect } from 'react'
import qs from 'qs'
import { useLocation } from 'react-router-dom'
import history from 'core/router/history'
import CenterContainer from 'common/components/containers/CenterContainer'
import Spinner from '@wongnai/ui/components/Spinner'

const RootPage = () => {
  const { search } = useLocation()
  const { path } = qs.parse(search, {
    ignoreQueryPrefix: true,
  })

  useEffect(() => {
    if (path) {
      history.replace(path)
    }
  }, [path])

  return (
    <CenterContainer padding>
      <Spinner size="sm" />
    </CenterContainer>
  )
}

export default RootPage
