import styled from 'styled-components'
import { fontSizes, borders } from 'common/styles/mixins'
import { spaces } from '@wongnai/ui/styles/mixins'
import { white, gray } from '@wongnai/ui/styles/colors'

export const TitleContainer = styled.div`
  padding: ${spaces.large2};
  padding-bottom: 0;
  font-size: ${fontSizes.large2};
  font-weight: bold;
  border-top-left-radius: ${borders.default};
  border-top-right-radius: ${borders.default};
`

export const DescriptionContainer = styled.div`
  color: ${gray[700]};
  font-size: ${fontSizes.small1};
  padding: ${spaces.large2};
  padding-top: 0;
`

export const ActionContainer = styled.div`
  display: flex;
  background-color: ${white};
  border-bottom-left-radius: ${borders.default};
  border-bottom-right-radius: ${borders.default};
  border-top: 1px solid ${gray[300]};
  > :first-child {
    border-right: 1px solid ${gray[300]};
  }
  > * {
    box-sizing: content-box;
    padding: ${spaces.small1};
  }
`
