import styled from 'styled-components'
import { spaces, fontSizes } from '@wongnai/ui/styles/mixins'
import { gray } from '@wongnai/ui/styles/colors'

export const TitleContainer = styled.div`
  padding: ${spaces.large2};
  padding-bottom: 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
`

export const DescriptionContainer = styled.div`
  padding: 0 ${spaces.large2};
  font-size: ${fontSizes.small1};
  color: ${gray[700]};
  text-align: center;
`

export const SettingContainer = styled.div`
  padding: ${spaces.large2};
  min-height: 190px;
  > * {
    &:not(:last-child) {
      margin-bottom: ${spaces.large2};
    }
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${spaces.normal} ${spaces.large1};
  background-color: ${gray[100]};
  > * {
    &:first-child {
      margin-right: ${spaces.large1};
    }
  }
`

export const Setting = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
