import styled from 'styled-components'
import Gap from '@wongnai/ui/components/Gap'
import { borders, fontFamilies } from 'common/styles/mixins'
import { spaces } from '@wongnai/ui/styles/mixins'
import { gray } from '@wongnai/ui/styles/colors'

export const FormContainer = styled(Gap)`
  width: 100%
  max-width: 340px
  text-align: center
  padding: ${spaces.large3}
  background-color: ${gray[100]}
  border-radius: ${borders.default}
  .fixed-height {
    height: 40px
  }

  .text-end {
    width: fit-content
    align-self: flex-end
  }

  .cursor {
    cursor: pointer
  }
`

export const Header = styled.div`
  font-family: ${fontFamilies.highlight}
  font-weight: bold
  white-space: pre-wrap
`
