import WongnaiAnalytics from '@wongnai/analytics/promise'
import { TrackParams } from '@wongnai/analytics/types'
import config from 'core/config'

const WongnaiTracker = {
  initTracker() {
    WongnaiAnalytics.then(instance => {
      instance.tracker?.setTrackerUrl(config.tracker.wongnai.url)
      instance.tracker?.setSiteId(config.tracker.wongnai.siteId)
      instance.tracker?.trackPageView()
    })
  },
  trackView(url: string) {
    WongnaiAnalytics.then(instance => {
      instance.tracker?.setCustomUrl(url)
      instance.tracker?.trackPageView()
    })
  },
  trackEvent({
    category,
    action,
    name,
    value,
    screenName,
    customData,
  }: TrackParams) {
    WongnaiAnalytics.then(instance => {
      instance.tracker?.trackEventWithScreenName({
        category,
        action,
        name,
        value,
        screenName,
        customData,
      })
    })
  },

  setUser(uid: string) {
    WongnaiAnalytics.then(instance => {
      instance.tracker?.setUserId(uid)
    })
  },
  clearUser() {
    WongnaiAnalytics.then(instance => {
      instance.tracker?.resetUserId()
    })
  },
}

export default WongnaiTracker
