import vconsole from 'vconsole'

function initVConsole() {
  if (process.env.REACT_APP_BUILD_ENV === 'dev') {
    // eslint-disable-next-line
    new vconsole()
  }
}

export default initVConsole
