import React, { HTMLAttributes, Fragment, useEffect } from 'react'
import Overlay from '@wongnai/ui/components/Overlay'
import { ModalContainer } from './styled'

export interface BaseModalProps extends HTMLAttributes<HTMLDivElement> {
  isOpen: boolean
  onImpression?: () => void
}

const BaseModal = ({
  className,
  isOpen,
  children,
  onImpression,
}: BaseModalProps) => {
  useEffect(() => {
    if (!!onImpression && isOpen) {
      onImpression()
    }
  }, [onImpression, isOpen])

  if (isOpen) {
    return (
      <Fragment>
        <ModalContainer className={className}>{children}</ModalContainer>
        <Overlay isOpen={isOpen} zIndex={0} />
      </Fragment>
    )
  }
  return null
}

export default BaseModal
