import React, { useCallback, useState } from 'react'
import CenterContainer from 'common/components/containers/CenterContainer'
import CloseCircleIcon from '@wongnai/ui/components/Icon/icons/close-circle'
import OTPInput from '@wongnai/ui/components/OTPInput'
import CountdownTimer from 'modules/user/components/CountdownTimer'
import { getDisplayPhoneNumber } from 'common/utils/phoneUtils'
import Text from '@wongnai/ui/components/Text'
import { spaces } from '@wongnai/ui/styles/mixins'
import statusResolver from 'common/utils/resolvers/statusResolver'
import { isBadRequest, isUnauthorized } from 'common/utils/statusUtils'
import useConnectionCondition from 'common/hooks/useConnectionCondition'
import { Title, Description, ResendOTP, Suffix } from './styled'
import usePhoneAuth from './usePhoneAuth'

const renderSuffix = (error?: E | null) =>
  error && <Suffix icon={CloseCircleIcon} size="20" />

const renderError = (error?: E | null) => {
  const statusCode = statusResolver(error)
  if (isBadRequest(statusCode)) {
    return 'รหัส OTP หมดอายุ กรุณากดรับรหัสใหม่'
  } else if (isUnauthorized(statusCode)) {
    return 'รหัสยืนยันไม่ถูกต้อง'
  }
  return ''
}

const OTPPage = () => {
  useConnectionCondition({ requireAddFriend: true })

  const { phoneNumber, error, loginWithPhone, requestOtp } = usePhoneAuth()
  const [isActiveTimer, setIsActiveTimer] = useState(false)

  const onComplete = useCallback(
    otp => {
      loginWithPhone(phoneNumber, otp)
    },
    [phoneNumber],
  )

  const request = useCallback(() => {
    setIsActiveTimer(true)
    requestOtp(phoneNumber)
  }, [phoneNumber])

  return (
    <CenterContainer padding>
      <Title>ใส่รหัสยืนยัน</Title>
      <Description>{`กรุณาใส่รหัสยืนยันตัวตนที่ถูกส่งไปยังหมายเลข\n${getDisplayPhoneNumber(
        phoneNumber,
      )}`}</Description>
      <OTPInput
        length={6}
        onComplete={onComplete}
        suffix={renderSuffix(error)}
        errMsg={renderError(error)}
      />
      <ResendOTP isActiveTimer={isActiveTimer} onClick={request}>
        <Text margin={`0 ${spaces.small1} 0 0`}>ส่งรหัสอีกครั้ง</Text>
        <CountdownTimer
          isActive={isActiveTimer}
          setIsActive={setIsActiveTimer}
          time={30}
        />
      </ResendOTP>
    </CenterContainer>
  )
}

export default OTPPage
