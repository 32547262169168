import styled from 'styled-components'
import { spaces, fontSizes, lineHeights } from '@wongnai/ui/styles/mixins'
import { gray } from '@wongnai/ui/styles/colors'

export const CrossContainer = styled.div`
  margin-right: ${spaces.large2};
  margin-top: ${spaces.large1};
  text-align: right;
`

export const TitleContainer = styled.div`
  margin: 0 ${spaces.large2};
  font-weight: bold;
  font-size: ${fontSizes.large1};
  line-height: ${lineHeights.large1};
  text-align: center;
`

export const DescriptionContainer = styled.div`
  margin: 0 ${spaces.large2};
  font-size: ${fontSizes.small1};
  color: ${gray[700]};
  text-align: center;
`

export const RemarkContainer = styled.div`
  margin: 0 ${spaces.large2} ${spaces.large1} ${spaces.large2};
  font-size: ${fontSizes.small2};
  color: ${gray[700]};
  text-align: center;
`

export const ScrollContainer = styled.div`
  height: 320px;
  margin: 0 ${spaces.large2} ${spaces.large2} ${spaces.large2};
  overflow-y: scroll;
`

export const SendingTimeItem = styled.div`
  width: 100%;
  height: 40px;
  border-top: 1px solid ${gray[300]};
  font-family: DB Heavent Now;
  font-style: normal;
  font-weight: normal;
  font-size: ${fontSizes.large2};
  line-height: ${lineHeights.large2};

  display: flex;
  justify-content: center;
  align-items: center;
`
