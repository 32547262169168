import { useState, useEffect } from 'react'
import { BusinessSetting } from 'modules/business/types/business'
import { TIME_DATA } from 'modules/report/components/SettingsOption/data'
import { NotificationType } from 'modules/report/constants'

export type SettingsType = 'notificationSetting' | 'dailyReportSendingTime'

interface Props {
  type: SettingsType
  setting: BusinessSetting
}

function useDescriptionFromType({ type, setting }: Props): string {
  const [description, setDescription] = useState('')

  const {
    enableReceivingDailyReport,
    monthlyReportEnabled,
    dailyReviewEnabled,
    dailyReportSendingTime,
    subscribePayoutLineNotifyEnabled,
  } = setting

  useEffect(() => {
    if (type === 'notificationSetting') {
      const enabled = []
      if (enableReceivingDailyReport) {
        enabled.push(NotificationType.DailySales)
      }
      if (monthlyReportEnabled) {
        enabled.push(NotificationType.MonthlySales)
      }
      if (dailyReviewEnabled) {
        enabled.push(NotificationType.Reviews)
      }
      if (subscribePayoutLineNotifyEnabled) {
        enabled.push(NotificationType.Payout)
      }

      if (enabled.length === 0) {
        setDescription('(ไม่มีการตั้งค่าการแจ้งเตือน)')
      } else {
        setDescription(enabled.join(', '))
      }
    } else if (type === 'dailyReportSendingTime') {
      const initialTime = TIME_DATA.find(
        time => dailyReportSendingTime === time.value,
      )
      if (initialTime) {
        setDescription(initialTime.title)
      }
    }
  }, [
    enableReceivingDailyReport,
    monthlyReportEnabled,
    dailyReviewEnabled,
    dailyReportSendingTime,
    subscribePayoutLineNotifyEnabled,
    type,
  ])

  return description
}

export default useDescriptionFromType
