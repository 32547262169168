import React, { useCallback } from 'react'
import liff from '@line/liff'

import config from 'core/config'
import useInject from 'common/hooks/useInject'
import CenterContainer from 'common/components/containers/CenterContainer'
import { spaces } from '@wongnai/ui/styles/mixins'
import ActionText from 'common/components/ActionText'
import {
  ContentContainer,
  TextContainer,
  LinkButton,
  Detail,
  Title,
} from './styled'

const OAVerifyPage = () => {
  const { getFriendShip, goToAuthenticate } = useInject(
    ({ connectionStore }) => ({
      getFriendShip: () => connectionStore.getFriendShip(),
      goToAuthenticate: () => connectionStore.goToAuthenticate(),
    }),
  )

  const onClickLinkButton = useCallback(() => {
    window.location.href = config.oaDeepLink
    setTimeout(async () => {
      const friendFlag = await getFriendShip()
      if (friendFlag) {
        goToAuthenticate()
      } else {
        liff.closeWindow()
      }
    }, 3000)
  }, [])

  const onClickCloseButton = useCallback(() => {
    liff.closeWindow()
  }, [])

  return (
    <CenterContainer padding>
      <ContentContainer type="vertical" size={spaces.large3}>
        <TextContainer type="vertical" size={spaces.normal}>
          <Title>กรุณาเพิ่มเพื่อนเพื่อสมัครรับบริการ</Title>
          <Detail>
            {'เพิ่ม Wongnai Owner Connect เป็นเพื่อน\nเพื่อรับแจ้งยอดขายรายวัน'}
          </Detail>
        </TextContainer>
        <LinkButton onClick={onClickLinkButton} size="large">
          เพิ่มเพื่อน
        </LinkButton>
        <ActionText onClick={onClickCloseButton}>ออกจากหน้า</ActionText>
      </ContentContainer>
    </CenterContainer>
  )
}

export default OAVerifyPage
