import React, { ReactNode } from 'react'
import OwnerConnectLogo from 'common/img/owner-connect-logo.svg'
import { Header, Logo, Title } from './styled'

type Props = {
  children: ReactNode
  logoHeight?: string
}

const PageHeader = ({ children, logoHeight }: Props) => (
  <Header>
    <Logo
      height={logoHeight}
      src={OwnerConnectLogo}
      alt="wongnai owner connect"
    />
    <Title>{children}</Title>
  </Header>
)

export default PageHeader
