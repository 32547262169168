import { client } from 'core/api'

export const lineLogin = (email: string, password: string) => {
  return client.fetch({
    method: 'post',
    path: '/line/login',
    body: {
      email,
      password,
    },
  })
}

export const requestOtp = (phoneNumber: string) => {
  return client.fetch({
    method: 'post',
    path: '/line/request-otp',
    body: {
      phoneNumber,
    },
  })
}

export const phoneLogin = (phoneNumber: string, code: string) => {
  return client.fetch({
    method: 'post',
    path: '/line/phone-login',
    body: {
      phoneNumber,
      code,
    },
  })
}

export const resetPassword = (email: string) => {
  return client.fetch({
    method: 'post',
    path: '/line/reset-password',
    body: {
      email,
    },
  })
}

export const linePosLogin = (email: string, password: string) => {
  return client.fetch({
    method: 'post',
    path: '/line/login/pos',
    body: {
      email,
      password,
    },
  })
}

export const getAccountsStatus = () => {
  return client.fetch({
    method: 'get',
    path: '/line/user-connection-detail',
  })
}
