import React from 'react'
import ContentContainer from 'common/components/containers/ContentContainer'
import CenterContainer from 'common/components/containers/CenterContainer'
import bear from 'core/error/images/internal-server-error-bear.svg'
import Gap from '@wongnai/ui/components/Gap'
import { spaces } from '@wongnai/ui/styles/mixins'
import { Img, Header, Description } from './styled'

const ErrorComponent = () => (
  <ContentContainer>
    <CenterContainer>
      <Img src={bear} width="250px" alt="bear" />
      <Gap type="vertical" size={spaces.normal}>
        <Header>เกิดข้อผิดพลาดบางอย่าง</Header>
        <Description>
          เรากำลังเร่งดำเนินการแก้ไข ขออภัยในความไม่สะดวก
        </Description>
      </Gap>
    </CenterContainer>
  </ContentContainer>
)

export default ErrorComponent
