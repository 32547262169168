import styled from 'styled-components'
import { spaces } from '@wongnai/ui/styles/mixins'
import ContentContainer from 'common/components/containers/ContentContainer'

export const PageContainer = styled(ContentContainer)`
  padding: ${spaces.large1};
  .fixed-height {
    height: 40px;
  }
  .responsive {
    width: 100%;
  }
`

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  padding: ${spaces.large1};
  width: 100%;
`
